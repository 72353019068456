<template>
  <div>
    <div class="item" v-for="(item,index) in list" :key="index">
      <div class="title">
        <div class="left">
          <i></i>
          <p>{{item.benefitName}}</p>
        </div>
        <div class="right">
          <p>{{item.insuredAmountShow}}</p>
        </div>
      </div>
      <div class="detail">
        <div class="msg">
            <p>{{item.benefitDetails}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {productBenefitDetail} from "../utils/api";
// import rrwebPlayer from 'rrweb-player'
export default {
  components: {},
  data() {
    return {
      list:[]
    };
  },
  async created() {
    this.$toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0,
    });
    await this.productBenefitDetail();
    this.$toast.clear();
  },
  methods: {
    //权益详情
    productBenefitDetail(){
      let params = {
        plansId: this.$route.query.id,
      }
      productBenefitDetail(params).then(res => {
        console.log(res)
        this.list = res.result;
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.item{
  padding-top: 13px;
  padding-bottom: 7px;
      display: flex;
    align-items: center;
    justify-content: space-between;
}
.title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left{
    display: flex;
    align-items: center;
    i{
      width: 4px;
      height: 16px;
      background: $primary-color;
      border-radius: 0px 2px 2px 0px;
    }
    p{
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      margin-left: 6px;
    }
  }
  .right{
    padding-right: 10px;
    p{
      font-size: 16px;
      font-weight: 500;
      color: $primary-color;
      line-height: 22px;
    }
  }
}
.detail{
  margin: 10px 10px 0;
  .msg{
    padding: 9px 12px;
    background: $bg7;
    p{
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      text-align: justify;
    }
  }
}

</style>